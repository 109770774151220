import React from 'react';
// import Select, { OptionTypeBase } from 'react-select';
import Slider from '@material-ui/core/Slider';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { Feature } from 'shared/types/Feature';

interface ClusterControlsProps {
    features: Array<Feature>;
    isShowingClusterFeature: boolean;
    setIsShowingClusterFeature: (isShowingClusterFeature: boolean) => void;
    recommendedClusterNumbers: Array<number>;
    onSubmit: Function;
    onSelectedFeatureChange: (selectedFeatures: Array<Feature>) => void;
    selectedFeatures: Array<Feature>;
    numClusters: number;
    onNumClustersChanged: (numClusters: number) => void;
    clusterLoading: boolean;
}

export const ClusterControls: React.FC<ClusterControlsProps> = ({
    features,
    // setIsShowingClusterFeature,
    // isShowingClusterFeature,
    recommendedClusterNumbers,
    // onSubmit,
    // selectedFeatures,
    // onSelectedFeatureChange,
    numClusters,
    onNumClustersChanged,
    clusterLoading,
}) => {
    const featureOptions = features.map((f) => ({
        value: f.id,
        label: f.name,
    }));

    return (
        <div style={{ width: '100%' }}>
            {/* TODO - possibly bring the <Select> back in when adding multiple select */}
            {/* <Select
                isDisabled={true}
                options={featureOptions}
                value={selectedFeatures.map((sf) =>
                    featureOptions.find((fo) => fo.value === sf.id)
                )}
                onChange={(newValue) => {
                    if (newValue) {
                        onSelectedFeatureChange(
                            newValue.map(
                                (sf: OptionTypeBase) => features.find((f) => f.id == sf.value)!
                            )
                        );
                    }
                    // setSelectedFeatures(newValue.map((f) => f.value));
                }}
            /> */}
            <h3>{numClusters} Clusters</h3>
            <Slider
                marks={true}
                defaultValue={numClusters}
                value={numClusters}
                valueLabelDisplay="auto"
                step={1}
                min={1}
                max={100}
                onChange={(event, numClusters) => {
                    onNumClustersChanged(numClusters as number);
                }}
            />
            <h3>Suggested Clusters</h3>
            <ButtonGroup variant="text">
                {recommendedClusterNumbers.map((n) => (
                    <Button onClick={() => onNumClustersChanged(n)}>{n}</Button>
                ))}
            </ButtonGroup>
            {clusterLoading && <h2>Loading...</h2>}
        </div>
    );
};
