import { useState, useCallback } from 'react';
import { FeatureDatum } from 'shared/types/FeatureDatum';

const server_url = process.env.REACT_APP_SERVER_URL
    ? process.env.REACT_APP_SERVER_URL
    : 'http://127.0.0.1:5000';

export const useClusteringResults = (
    noClusters: number,
    alpha: number,
    data: any
) => {
    const [clusters, setClusters] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const calculateClusters = useCallback(() => {
        if (data) {
            setLoading(true);
            const body = JSON.stringify({
                alpha,
                cluster_no: noClusters,
                data: Object.entries(data)
                    .filter((f: any) => f[0] !== 'boro_ct201' && f[0] !== '')
                    .map((f: any) => ({
                        census_tract_id: f[0],
                        features: f[1],
                    })),
            });

            fetch(`${server_url}/handle_request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body,
            })
                .then((resp) => resp.json())
                .then((data) => {
                    setLoading(false);
                    setClusters(data);
                });
        }
    }, [noClusters, alpha, data]);
    return { clusters, calculateClusters, loading };
};
