import React from 'react';
import { DeckGL } from '@deck.gl/react';
import { StaticMap, NavigationControl } from 'react-map-gl';
import { Feature } from 'shared/types/Feature';
import { Tooltip } from './MapTooltip';

const INITIAL_VIEW_STATE = {
    longitude: -74.006,
    latitude: 40.7128,
    zoom: 10,
    pitch: 0,
    bearing: 0,
};

interface MapProps {
    hoverInfo?: any;
    onHover: (i: any, e: any) => void;
    layers: any[];
    features: Feature[] | undefined;
}

export const Map: React.FC<MapProps> = ({
    onHover,
    hoverInfo,
    layers,
    features,
}) => {
    return (
        <DeckGL
            width={'100%'}
            height={'100%'}
            controller={true}
            initialViewState={INITIAL_VIEW_STATE}
            layers={layers}
            onHover={onHover}
        >
            <StaticMap
                mapboxApiAccessToken={
                    'pk.eyJ1Ijoic3R1YXJ0LWx5bm4iLCJhIjoiY2ttNDZsdTVuMDFrazJ2b2Z6bmV0dDlpZiJ9.VHg-mUZEYo4shwH4s_QnKA'
                }
            />

            {hoverInfo?.object && (
                <Tooltip
                    x={hoverInfo.x}
                    y={hoverInfo.y}
                    features={features}
                    properties={hoverInfo.object.properties}
                />
            )}
        </DeckGL>
    );
};
