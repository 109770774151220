import React from 'react';
import styled from 'styled-components';

import { TEAL_COLOR_SCALE } from 'pages/HomePage/MapLayerProcessing';

const ColorSquare = styled.div<{ col: [number, number, number, number] }>`
    width: 20px;
    height: 20px;
    background-color: ${({ col }) =>
        `rgba(${col[0]}, ${col[1]}, ${col[2]}, 255)`};
`;

const ColorLegendContainer = styled.ul`
    list-style: none;
    margin: 10px 0px;
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    li {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 10px;
    }
    span {
        font-size: 10px;
    }
`;
interface ColorLegendProps {
    bins: number[];
}
export const ColorLegend: React.FC<ColorLegendProps> = ({ bins }) => {
    // console.log('BINS ', bins);
    return (
        <ColorLegendContainer>
            {bins.map((bin, index) => (
                <li key={index}>
                    <ColorSquare col={TEAL_COLOR_SCALE[index]} />
                    <span>
                        {bin.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                        })}
                    </span>
                </li>
            ))}
        </ColorLegendContainer>
    );
};
