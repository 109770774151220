import { createContext, useEffect, useReducer } from 'react';
import { Boundary } from 'shared/types/Boundary';
import { Feature } from 'shared/types/Feature';
import { FeatureDatum } from 'shared/types/FeatureDatum';

export enum DataActionType {
    UPDATE_AVAILABLE_FEATURES,
    UPDATE_AVAILABLE_BOUNDARIES,
    ADD_FEATURE_DATA,
    UPDATE_LOADING_STATE,
    SET_MANIFEST_ERROR,
}

type DataProviderProps = {
    children: React.ReactNode;
};

type UPDATE_AVAILABLE_FEATURES = {
    type: DataActionType.UPDATE_AVAILABLE_FEATURES;
    payload: Feature[];
};
type UPDATE_AVAILABLE_BOUNDARIES = {
    type: DataActionType.UPDATE_AVAILABLE_BOUNDARIES;
    payload: Boundary[];
};

type UPDATE_LOADING_STATE = {
    type: DataActionType.UPDATE_LOADING_STATE;
    payload: boolean;
};

type ADD_FEATURE_DATA = {
    type: DataActionType.ADD_FEATURE_DATA;
    payload: {
        id: string;
        data: FeatureDatum[];
    };
};

type SET_MANIFEST_ERROR = {
    type: DataActionType.SET_MANIFEST_ERROR;
    payload: string | null;
};

type DataAction =
    | UPDATE_AVAILABLE_FEATURES
    | UPDATE_AVAILABLE_BOUNDARIES
    | ADD_FEATURE_DATA
    | UPDATE_LOADING_STATE
    | SET_MANIFEST_ERROR;

interface DataContextState {
    features: Feature[];
    boundaries: Boundary[];
    featureData: { [id: string]: FeatureDatum[] };
    loadingFeatureManifest: boolean;
    manifestError: string | null;
    featureFetchError: string | null;
}

const initalState: DataContextState = {
    features: [],
    boundaries: [],
    featureData: {},
    loadingFeatureManifest: false,
    manifestError: null,
    featureFetchError: null,
};

export const DataContext = createContext<{
    state: DataContextState;
    dispatch: React.Dispatch<any>;
}>({
    state: initalState,
    dispatch: () => null,
});

const { Provider } = DataContext;

function reducer(state: DataContextState, action: DataAction) {
    switch (action.type) {
        case DataActionType.UPDATE_LOADING_STATE:
            return {
                ...state,
                loadingFeatureManifest: action.payload,
            };
        case DataActionType.SET_MANIFEST_ERROR:
            return {
                ...state,
                manifestError: action.payload,
            };
        case DataActionType.UPDATE_AVAILABLE_FEATURES:
            return {
                ...state,
                features: action.payload,
            };
        case DataActionType.UPDATE_AVAILABLE_BOUNDARIES:
            return {
                ...state,
                boundaries: action.payload,
            };
        case DataActionType.ADD_FEATURE_DATA:
            return {
                ...state,
                featureData: {
                    ...state.featureData,
                    [action.payload.id]: action.payload.data,
                },
            };
        default:
            return state;
    }
}

export function DataProvider({ children }: DataProviderProps): JSX.Element {
    const [state, dispatch] = useReducer(reducer, initalState);
    useEffect(() => {
        dispatch({
            type: DataActionType.UPDATE_LOADING_STATE,
            payload: true,
        });
        fetch('/datasets/manifest.json')
            .then((resp) => resp.json())
            .then((manifest) => {
                dispatch({
                    type: DataActionType.UPDATE_AVAILABLE_FEATURES,
                    payload: manifest.features,
                });
                dispatch({
                    type: DataActionType.UPDATE_AVAILABLE_BOUNDARIES,
                    payload: manifest.boundaries,
                });
            })
            .catch((e) =>
                dispatch({
                    type: DataActionType.SET_MANIFEST_ERROR,
                    payload: e.toString(),
                })
            )
            .finally(() => {
                dispatch({
                    type: DataActionType.UPDATE_LOADING_STATE,
                    payload: false,
                });
                dispatch({
                    type: DataActionType.SET_MANIFEST_ERROR,
                    payload: null,
                });
            });
    }, []);

    // console.log('Data context state ', state);

    return <Provider value={{ state, dispatch }}>{children}</Provider>;
}
