import axios from 'axios';
import Papa from 'papaparse';
import { FeatureDatum } from 'shared/types/FeatureDatum';
import { Feature } from 'shared/types/Feature';

export const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL
        ? process.env.REACT_APP_SERVER_URL
        : window.origin,
});

export const loadFeature = (feature: Feature) => {
    return new Promise((resolve, reject) => {
        Papa.parse(`/datasets/features/${feature.dataFileUrl}`, {
            download: true,
            complete: (results) => {
                let data: FeatureDatum[] = results.data.map((r: any) => ({
                    boro_ct2010: r[0],
                    value: parseFloat(r[1]),
                }));

                resolve(data);
            },
            error: (e) => {
                reject(e);
                console.log('something went wrong');
            },
        });
    });
};
