import React from 'react';
import { Feature } from 'shared/types/Feature';
import Select from 'react-select';
import { ColorLegend } from 'components/ColorLegend/ColorLegend';

interface FeatureControlsProps {
    isShowingFeature: boolean;
    setIsShowingFeature: (isShowingFeature: boolean) => void;
    activeFeatureId: string | undefined;
    onSelectFeature: (feature: Feature) => void;
    features: Feature[] | undefined;
    bins: number[] | null;
}

type OptionType = {
    value: string;
    label: string;
};

const emptyFeature: Feature = {
    id: 'None',
    name: 'No features',
    dataFileUrl: '',
    description: '',
    source: '',
    category: '',
};

export const FeatureControls: React.FC<FeatureControlsProps> = ({
    features,
    activeFeatureId,
    onSelectFeature,
    isShowingFeature,
    setIsShowingFeature,
    bins,
}) => {
    const featureOptions: OptionType[] = features
        ? features.map((f) => ({ value: f.id, label: f.name }))
        : [];

    const activeFeatureOption = featureOptions.find(
        (fo) => fo.value === activeFeatureId
    );

    return (
        <div style={{ width: '100%' }}>
            <Select
                options={featureOptions}
                value={activeFeatureOption}
                onChange={(selectedFeature) => {
                    const feature: Feature | undefined = features?.find(
                        (f) => f.id === selectedFeature?.value
                    );
                    if (feature) {
                        onSelectFeature(feature);
                    }
                }}
            />
            {isShowingFeature && bins && <ColorLegend bins={bins} />}
        </div>
    );
};
