import React, { ReactElement } from 'react';
import { Styled } from './NavBarStyles';

type Props = {
    name?: String;
    icon?: ReactElement;
    children?: ReactElement;
};

export const NavBarButton: React.FC<Props> = ({ name, icon, children }) => {
    return (
        <Styled.NavBarButton>
            {icon}
            {name && <p>{name}</p>}
            {children}
        </Styled.NavBarButton>
    );
};
