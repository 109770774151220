import React, { useEffect, useState, useMemo } from 'react';
import { Styles } from './HomePageStyles';
import { Map } from 'components/Map/Map';
import type { MjolnirPointerEvent } from 'mjolnir.js';
import type { PickingInfo } from 'deck.gl/typed';
import { useLayers } from 'hooks/useLayers';
import { useLayerWithData } from 'hooks/useLayerWithData';
import Accordion from '@material-ui/core/Accordion';
import { Feature } from 'shared/types/Feature';
import { useAvailableBoundaries } from 'hooks/useAvailableBoundaries';
import { useAvailableFeatures } from 'hooks/useAvailableFeatures';
import { useClusteringResults } from 'hooks/useClusteringResults';
import { ClusterControls } from 'components/ClusterControls/ClusterControls';
import { layersToDeckGLLayers } from './MapLayerProcessing';
import { useFeature } from 'hooks/useFeature';
import { useCombinedFeatures } from 'hooks/useCombinedFeatures';
import { AccordionDetails, AccordionSummary, Switch } from '@material-ui/core';
import { FeatureControls } from 'components/ClusterControls/FeatureControls';
import { BoundaryControls } from 'components/ClusterControls/BoundaryControls';
import { DATA_CLINIC_COLOR_PALETTE_CATEGORICAL } from 'shared/types/constants/ColorPalettes';

const TOKEN = process.env.MAPBOX_TOKEN;

export const HomePage: React.FC = () => {
    const { layers, addLayer, removeLayer } = useLayers();
    const { features } = useAvailableFeatures();
    const { boundaries } = useAvailableBoundaries();

    const [isShowingClusterFeature, setIsShowingClusterFeature] = useState<
        boolean
    >(false);
    const [isShowingBoundary, setIsShowingBoundary] = useState<boolean>(false);
    const [isShowingFeature, setIsShowingFeature] = useState<boolean>(true);

    const [selectedBoundaryId, setSelectedBoundaryId] = useState<
        string | undefined
    >();
    const [selectedFeatureId, setSelectedFeatureId] = useState<
        string | undefined
    >(undefined);

    const [selectedFeature, setSelectedFeature] = useState<Feature | undefined>(
        undefined
    );

    const [clusteringFeatures, setClusteringFeatures] = useState<Feature[]>([]);
    const [clusterCount, setClusterCount] = useState<number>(10);

    const censusTracts = layers.find((l) => l.name === 'census_tracts');
    const boundaryLayer = layers.find((l) => l.name === selectedBoundaryId);
    const clusterLayer = layers.find((l) => l.name === 'clusters');

    const { loading, data } = useFeature(selectedFeature);

    const { layerWithData: censusTractsWithData, bins } = useLayerWithData(
        data,
        censusTracts,
        'boro_ct2010',
        'quantiles'
    );

    const [hoverInfo, setHoverInfo] = useState<PickingInfo>();

    const onHover = (info: PickingInfo, event: MjolnirPointerEvent) => {
        if (info?.layer?.id && info.layer.id === 'clusters') {
            setHoverInfo(info);
        }
    };

    let mapLayers = useMemo(() => {
        let layersToMap = [];
        if (isShowingFeature && censusTractsWithData) {
            layersToMap.push(censusTractsWithData);
        } else if (censusTracts) {
            layersToMap.push(censusTracts);
        }
        if (isShowingClusterFeature && clusterLayer) {
            layersToMap.push(clusterLayer);
        }

        if (isShowingBoundary && boundaryLayer) {
            layersToMap.push(boundaryLayer);
        }
        return layersToDeckGLLayers(layersToMap);
    }, [
        censusTractsWithData,
        censusTracts,
        boundaryLayer,
        clusterLayer,
        isShowingBoundary,
        isShowingFeature,
        isShowingClusterFeature,
    ]);

    useEffect(() => {
        let foundFeature = features.find((f) => f.id == selectedFeatureId);
        if (foundFeature) {
            setSelectedFeature(foundFeature);
            setClusteringFeatures([foundFeature]);
        }
        setIsShowingClusterFeature(false);
    }, [selectedFeatureId]);

    useEffect(() => {
        if (isShowingClusterFeature) {
            setIsShowingFeature(false);
            runClustering();
        } else {
            setIsShowingFeature(true);
        }
    }, [isShowingClusterFeature]);

    useEffect(() => {
        runClustering();
    }, [clusterCount]);

    useEffect(() => {
        if (selectedBoundaryId) {
            const boundary = boundaries.find(
                (b) => b.id === selectedBoundaryId
            );
            if (boundary) {
                layers.forEach((l) => {
                    if (l.name !== 'census_tracts' && l.name !== 'clusters') {
                        removeLayer(l.name);
                    }
                });
                addLayer({
                    name: boundary.id,
                    geomURL: `/datasets/boundaries/${boundary.datafile}`,
                    visible: true,
                });
            }
        }
    }, [selectedBoundaryId, boundaries]);

    useEffect(() => {
        addLayer({
            name: 'census_tracts',
            geomURL: `/datasets/boundaries/2010_census_tracts.geojson`,
            visible: true,
        });
    }, []);

    const { combinedData, loading: combinedLoading } = useCombinedFeatures(
        clusteringFeatures
    );

    const {
        clusters,
        calculateClusters,
        loading: clusterLoading,
    } = useClusteringResults(clusterCount, 0.2, combinedData);

    useEffect(() => {
        layers.forEach((l) => {
            if (l.name === 'clusters') {
                removeLayer(l.name);
            }
        });
        addLayer({
            name: 'clusters',
            data: clusters,
            visible: true,
            fillColor: (f: any) => {
                return DATA_CLINIC_COLOR_PALETTE_CATEGORICAL[
                    f.properties.cluster % 10
                ];
            },
        });
    }, [clusters]);

    const runClustering = () => {
        calculateClusters();
    };

    // console.log('layers ', layers, mapLayers);

    return (
        <Styles.HomePage>
            <Styles.Details>
                <Accordion expanded={true}>
                    <AccordionSummary>
                        <div>Choose data to visualize</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        {loading && <h2>Loading</h2>}
                        {!loading && features && (
                            <FeatureControls
                                isShowingFeature={isShowingFeature}
                                setIsShowingFeature={setIsShowingFeature}
                                features={features}
                                activeFeatureId={selectedFeatureId}
                                onSelectFeature={(b) => {
                                    setSelectedFeatureId(b.id);
                                }}
                                bins={bins}
                            />
                        )}
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={!!selectedFeature}>
                    <AccordionSummary>
                        <div>
                            <span>
                                Cluster{' '}
                                {selectedFeature
                                    ? `by ${selectedFeature.name}`
                                    : `data`}
                            </span>
                            <Switch
                                disabled={!selectedFeature}
                                checked={isShowingClusterFeature}
                                onClick={() => {
                                    setIsShowingClusterFeature(
                                        !isShowingClusterFeature
                                    );
                                }}
                            />
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ClusterControls
                            isShowingClusterFeature={isShowingClusterFeature}
                            setIsShowingClusterFeature={
                                setIsShowingClusterFeature
                            }
                            features={features}
                            recommendedClusterNumbers={[10, 34, 50]}
                            onSubmit={runClustering}
                            selectedFeatures={clusteringFeatures}
                            onSelectedFeatureChange={setClusteringFeatures}
                            onNumClustersChanged={setClusterCount}
                            numClusters={clusterCount}
                            clusterLoading={clusterLoading}
                        />
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={!!selectedFeature}>
                    <AccordionSummary>
                        <div>
                            Boundaries
                            <Switch
                                disabled={!selectedFeature}
                                checked={isShowingBoundary}
                                onClick={() =>
                                    setIsShowingBoundary(!isShowingBoundary)
                                }
                            />
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        {loading && <h2>Loading</h2>}
                        {features && (
                            <BoundaryControls
                                isShowingBoundary={isShowingBoundary}
                                setIsShowingBoundary={setIsShowingBoundary}
                                boundaries={boundaries}
                                activeBoundayLayerId={selectedBoundaryId}
                                onSelectBoundary={(b) =>
                                    setSelectedBoundaryId(b.id)
                                }
                            />
                        )}
                    </AccordionDetails>
                </Accordion>
            </Styles.Details>
            <Styles.Map>
                <Map
                    onHover={onHover}
                    hoverInfo={hoverInfo}
                    layers={mapLayers}
                    features={features}
                />
            </Styles.Map>
        </Styles.HomePage>
    );
};
