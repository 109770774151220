import React, { ReactElement } from 'react';
import { Styled } from './NavBarStyles';

type Props = {
    appName: String;
    children: ReactElement;
};

export const NavBar: React.FC<Props> = ({ appName, children }) => {
    return (
        <Styled.NavBar>
            <Styled.NavBarInner>
                <h5>{appName}</h5>
                {children}
            </Styled.NavBarInner>
        </Styled.NavBar>
    );
};

export { NavBarButton } from './NavBarButton';
export const NavBarSeperator = Styled.NavBarSeperator;
