import styled, { ThemeProvider } from 'styled-components';

const NavBar = styled.div`
    background-color: ${(props) =>
        props.theme ? props.theme.colors.main : 'green'};
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.greys.dark};
    font-size: 1rem;
    h2 {
        overflow-wrap: anywhere;
        margin-bottom: 50px;
        font-size: 1.3rem;
    }
`;

const NavBarInner = styled.div`
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1;
`;

const NavBarSeperator = styled.div`
    flex: 1;
`;

const NavBarButton = styled.button`
    color: white;
    text-align: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
    svg {
        font-size: 1.5rem;
    }
`;

export const Styled = {
    NavBar,
    NavBarInner,
    NavBarButton,
    NavBarSeperator,
};
