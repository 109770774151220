import React, { createContext, useReducer } from 'react';
import { Layer } from 'shared/types/Layer';
export enum MapActionType {
    SET_LAYER_VISIBLE,
    SET_LAYER_INVISIBLE,
    ADD_LAYER,
    REMOVE_LAYER,
    UPDATE_LAYER,
}

type MapProviderProps = {
    children: React.ReactNode;
};

type SET_LAYER_VISIBLE = {
    type: MapActionType.SET_LAYER_VISIBLE;
    payload: string;
};

type SET_LAYER_INVISIBLE = {
    type: MapActionType.SET_LAYER_INVISIBLE;
    payload: string;
};

type ADD_LAYER = {
    type: MapActionType.ADD_LAYER;
    payload: Layer;
};

type REMOVE_LAYER = {
    type: MapActionType.REMOVE_LAYER;
    payload: string;
};

type UPDATE_LAYER = {
    type: MapActionType.UPDATE_LAYER;
    payload: Layer;
};

type MapAction =
    | SET_LAYER_VISIBLE
    | SET_LAYER_INVISIBLE
    | UPDATE_LAYER
    | REMOVE_LAYER
    | ADD_LAYER;

interface MapContextState {
    layers: Layer[];
}

const initalState: MapContextState = {
    layers: [],
};

export const MapContext = createContext<{
    state: MapContextState;
    dispatch: React.Dispatch<MapAction>;
}>({
    state: initalState,
    dispatch: () => null,
});

const Provider = MapContext.Provider;

function reducer(state: MapContextState, action: MapAction) {
    switch (action.type) {
        case MapActionType.ADD_LAYER:
            return {
                ...state,
                layers: [...state.layers, action.payload],
            };
        case MapActionType.REMOVE_LAYER:
            return {
                ...state,
                layers: state.layers.filter((l) => l.name != action.payload),
            };
        case MapActionType.UPDATE_LAYER:
            return {
                ...state,
                layers: state.layers.map((l) =>
                    l.name !== action.payload.name
                        ? l
                        : { ...l, ...action.payload }
                ),
            };
        default:
            return state;
    }
}

export function MapProvider({ children }: MapProviderProps): JSX.Element {
    const [state, dispatch] = useReducer(reducer, initalState);
    return <Provider value={{ state, dispatch }}>{children}</Provider>;
}
