import { useContext, useState } from 'react';
import { MapContext, MapActionType } from 'contexts/MapContext';
import { Layer } from 'shared/types/Layer';

export const useLayers = () => {
    const { state, dispatch } = useContext(MapContext);
    const { layers } = state;

    const addLayer = async (layer: Layer) => {
        if (layer.data) {
            dispatch({
                type: MapActionType.ADD_LAYER,
                payload: {
                    ...layer,
                    state: 'loaded',
                },
            });
        } else if (layer.geomURL) {
            dispatch({
                type: MapActionType.ADD_LAYER,
                payload: { ...layer, state: 'loading' },
            });

            try {
                const resp = await fetch(layer.geomURL);
                const data = resp.json();
                dispatch({
                    type: MapActionType.UPDATE_LAYER,
                    payload: { ...layer, state: 'loaded', data: data },
                });
            } catch {
                dispatch({
                    type: MapActionType.UPDATE_LAYER,
                    payload: {
                        ...layer,
                        state: 'failed',
                        error: 'Failed to get layer data',
                    },
                });
            }
        }
    };

    const removeLayer = (layerName: string) => {
        dispatch({
            type: MapActionType.REMOVE_LAYER,
            payload: layerName,
        });
    };

    return { layers, addLayer, removeLayer };
};
