import styled from 'styled-components';
import { Header } from '@dataclinic/dataclinic';
import { Paper } from 'components/Paper/Paper';

const HomePage = styled.div`
    width: 100%;
    height: 100%;
    grid-template-columns: 400px 1fr;
    grid-template-areas: 'details map';
    position: relative;
    display: grid;
`;

const Map = styled.div`
    grid-area: map;
`;
const Details = styled(Paper)`
    overflow-y: scroll;
    grid-area: details;
    background-color: rgba(255, 255, 255, 255);
    z-index: 1;
    padding: 0px 20px 0px 20px;
    box-sizing: border-box;
    ${Header} {
        text-align: left;
    }
`;

const Legend = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    z-index: 10;
`;

export const Styles = {
    HomePage,
    Map,
    Details,
    Legend,
};
