// export const DATA_CLINIC_COLOR_PALETTE_SEQUENTIAL = ['#ccedf2', '#80d2de','33b7ca','#00a5bd', '#007384', '#00424c', '#002126'];
export const DATA_CLINIC_COLOR_PALETTE_SEQUENTIAL = [
    [204, 237, 242, 255],
    [128, 210, 222, 255],
    [51, 183, 202, 255],
    [0, 165, 189, 255],
    [0, 115, 132, 255],
    [0, 66, 76, 255],
    [0, 33, 38, 255],
];

// export const DATA_CLINIC_COLOR_PALETTE_CATEGORICAL = ['#00a5bd', '#7a10aa', '#e6e6e6', '#fd1e46', '#f5d855', '#666666'];
export const DATA_CLINIC_COLOR_PALETTE_CATEGORICAL = [
    [0, 165, 189],
    [122, 16, 170],
    [230, 230, 230],
    [253, 30, 70],
    [245, 216, 85],
    [102, 102, 102],
    // expand the palette by repeating same colors with lower a-value (rgba format)
    [0, 165, 189, 175],
    [122, 16, 170, 175],
    [230, 230, 230, 175],
    [253, 30, 70, 175],
    [245, 216, 85, 175],
    [102, 102, 102, 175],
];
