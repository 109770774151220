import React from 'react';
import './App.css';
import { Link, Route } from 'react-router-dom';
import { AboutPage } from 'pages/AboutPage/AboutPage';
import { HomePage } from 'pages/HomePage/HomePage';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import {
    NavBar as OrigNavBar,
    NavBarButton as OrigNavBarButton,
} from 'components/NavBar/NavBar';
import {
    ResponsiveNavCenterFooterLayout,
    FooterArea,
    TSLinksBar,
    MainArea,
    NavArea,
    // NavBar as OrigNavBar,
    // NavBarButton as OrigNavBarButton,
    NavBarSeperator,
    DCLogoWhite,
} from '@dataclinic/dataclinic';
import { MapProvider } from 'contexts/MapContext';
import { DataProvider } from 'contexts/DataContext';

// TODO - move this fix to the dependency source
const NavBar: React.FC<React.PropsWithChildren<any>> = OrigNavBar;
const NavBarButton: React.FC<React.PropsWithChildren<any>> = OrigNavBarButton;

function App(props: any) {
    return (
        <div className="App">
            <DataProvider>
                <MapProvider>
                    <ResponsiveNavCenterFooterLayout>
                        <NavArea>
                            <NavBar appName="Newerhoods">
                                <Link to="/">
                                    <NavBarButton>
                                        <FontAwesomeIcon icon={faMap} />
                                        <p>Explore</p>
                                    </NavBarButton>
                                </Link>
                                <Link to="/about">
                                    <NavBarButton>
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                        <p>about</p>
                                    </NavBarButton>
                                </Link>

                                <NavBarSeperator />

                                <a href="https://dataclinic.twosigma.com">
                                    <DCLogoWhite />
                                </a>
                            </NavBar>
                        </NavArea>
                        <MainArea>
                            <Route path="/" exact={true}>
                                <HomePage />
                            </Route>
                            <Route path="/about" exact={true}>
                                <AboutPage />
                            </Route>
                        </MainArea>
                        <FooterArea>
                            <TSLinksBar />
                        </FooterArea>
                    </ResponsiveNavCenterFooterLayout>
                </MapProvider>
            </DataProvider>
        </div>
    );
}

export default App;
