import { useState, useEffect } from 'react';
import { Layer } from 'shared/types/Layer';
import { FeatureDatum } from 'shared/types/FeatureDatum';
import {
    createColorQuantizerFromDatums,
    QuantizerType,
} from 'pages/HomePage/MapLayerProcessing';
import { DATA_CLINIC_COLOR_PALETTE_SEQUENTIAL } from 'shared/types/constants/ColorPalettes';

const assignValAndCol = (
    data: FeatureDatum[],
    id: string,
    quantizer: QuantizerType,
    colorPalette: any
) => {
    const datum = data.find((d: FeatureDatum) => d.boro_ct2010 === id);

    const val_col = {
        val: datum?.value,
        fillColor: datum ? colorPalette[quantizer(datum)] : 'rgba(0,0,0,60)',
    };
    return val_col;
};

//Merges a geojson layer with a data layer using join ids
export const useLayerWithData = (
    data: FeatureDatum[] | null,
    layer?: Layer,
    layerIdCol?: string,
    symbolizeMethod?: string,
    colorPalette: any = DATA_CLINIC_COLOR_PALETTE_SEQUENTIAL
) => {
    const [mergedLayer, setMergedLayer] = useState<Layer | null>(null);
    const [quantBins, setQuantBins] = useState<number[] | null>(null);

    useEffect(() => {
        if (layer && layer.state == 'loaded' && layerIdCol) {
            if (data) {
                let [quant, bins] = createColorQuantizerFromDatums(data);
                setQuantBins(bins);
                layer.data.then((geoJSON: any) => {
                    const { features } = geoJSON;
                    const mergedFeatures = features.map((f: any) => ({
                        ...f,
                        properties: {
                            ...f.properties,
                            ...assignValAndCol(
                                data,
                                f.properties[layerIdCol],
                                quant,
                                colorPalette
                            ),
                        },
                    }));
                    setMergedLayer({
                        ...layer,
                        data: {
                            ...geoJSON,
                            features: mergedFeatures,
                        },
                    });
                });
            } else {
                setMergedLayer(layer);
            }
        }
    }, [data, layer, layerIdCol]);

    return { layerWithData: mergedLayer, bins: quantBins };
};
