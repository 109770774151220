import styled from 'styled-components';
import { Header } from '@dataclinic/dataclinic';
import { Paper } from 'components/Paper/Paper';

const MapTooltip = styled.div`
    background-color: white;
    position: absolute;
    zindex: 10;
    pointerevents: none;
    cursor: crosshair;
`;

export const Styles = {
    MapTooltip,
};
