import React from 'react';
import { Feature } from 'shared/types/Feature';
import { GeoJsonProperties } from 'geojson';
import { Styles } from './MapTooltipStyles';

export const Tooltip: React.FC<{
    x: number;
    y: number;
    features: Feature[] | undefined;
    properties?: GeoJsonProperties;
}> = ({ x, y, features, properties }) => {
    return (
        <Styles.MapTooltip style={{ left: x, top: y }}>
            <table>
                <tbody>
                    {properties &&
                        Object.keys(properties).map((p) => {
                            const feature = features?.filter(
                                (f) => f.id === p
                            )[0];
                            if (feature) {
                                return (
                                    <tr>
                                        <td>{feature.description}: </td>
                                        <td>
                                            {`${Number.parseFloat(
                                                properties[p]
                                            ).toFixed(2)} ${
                                                feature.units
                                                    ? feature.units
                                                    : ``
                                            }`}
                                        </td>
                                    </tr>
                                );
                            }
                        })}
                </tbody>
            </table>
        </Styles.MapTooltip>
    );
};
