import { useState, useEffect, useContext } from 'react';
import { DataContext } from 'contexts/DataContext';

export const useAvailableFeatures = () => {
    const { state } = useContext(DataContext);
    const {
        features,
        loadingFeatureManifest: loading,
        manifestError: error,
    } = state;
    return { features, loading, error };
};
