import { Layer } from 'shared/types/Layer';
import { FeatureDatum } from 'shared/types/FeatureDatum';
import { Feature } from 'shared/types/Feature';
import { GeoJsonLayer } from '@deck.gl/layers';
import { useFeature } from 'hooks/useFeature';
import * as scale from 'd3-scale';

// NOTE: For some reason I would get typescript complaints
// about these being RGBAColor incompatible if I did not have
// the any type. Strange.
export const TEAL_COLOR_SCALE: any = [
    [204, 237, 242, 255],
    [128, 210, 222, 255],
    [51, 183, 202, 255],
    [0, 165, 189, 255],
    [0, 115, 132, 255],
    [0, 66, 76, 255],
    [0, 33, 38, 255],
];

type BoundaryFeature = any;
export type QuantizerType = (input: FeatureDatum) => number;

type RegionToBoundaryAssociation = {
    [region_id: string]: {
        boundaryData: BoundaryFeature;
        layer: Layer;
    };
};

type RegionToFeatureDataAssociation = {
    [region_id: string]: FeatureDatum[];
};

export type MergedRegionData = {
    [region: string]: {
        featureData: FeatureDatum[];
        boundaryData: BoundaryFeature;
        quantizedData: number[];
    };
};

export const createColorQuantizerFromDatums = (
    inputData: FeatureDatum[]
): [QuantizerType, number[]] => {
    let justValues = inputData.map((d) => d.value);
    let numBins = TEAL_COLOR_SCALE.length;
    let bins = Array.from(Array(numBins).keys());
    let quantizer = scale.scaleQuantile().domain(justValues).range(bins);
    return [
        (input: FeatureDatum): number => {
            return quantizer(input.value);
        },
        quantizer.quantiles(),
    ];
};

const createRegionToFeatureDataMapping = (
    featureDatums: FeatureDatum[]
): Promise<RegionToFeatureDataAssociation> => {
    let regionToDatums: RegionToFeatureDataAssociation = {};
    featureDatums.forEach((datum) => {
        let regionId = datum.boro_ct2010;
        if (regionToDatums.hasOwnProperty(regionId)) {
            regionToDatums[regionId].push(datum);
        } else {
            regionToDatums[regionId] = [datum];
        }
    });
    return new Promise((resolve) => resolve(regionToDatums));
};

const createRegionToBoundaryMapping = (
    layers: Layer[]
): Promise<RegionToBoundaryAssociation> => {
    let regionToBoundaryData: RegionToBoundaryAssociation = {};
    let result = layers.map((layer) => {
        return layer.data.then((layerInfo: any) => {
            layerInfo.features.forEach((boundaryFeature: any) => {
                let regionId = boundaryFeature.properties.boro_ct2010;
                regionToBoundaryData[regionId] = {
                    boundaryData: boundaryFeature,
                    layer: layer,
                };
            });
        });
    });
    return Promise.all(result).then((_) => {
        return new Promise((resolve) => {
            resolve(regionToBoundaryData);
        });
    });
};

const mergeFeatureWithBoundaryDataAndQuantize = (
    featureData: RegionToFeatureDataAssociation,
    boundaryData: RegionToBoundaryAssociation,
    quantizer: QuantizerType
): MergedRegionData => {
    let mergedMapping: MergedRegionData = {};
    Object.keys(boundaryData).forEach((region) => {
        let boundaryDetails = boundaryData[region];
        mergedMapping[region] = {
            featureData: [],
            quantizedData: [],
            boundaryData: boundaryDetails.boundaryData,
        };
    });
    Object.keys(featureData).forEach((region) => {
        if (region in mergedMapping) {
            let regionFeats = featureData[region];
            mergedMapping[region].featureData.push(...regionFeats);
            mergedMapping[region].quantizedData.push(
                ...regionFeats.map(quantizer)
            );
        }
    });
    return mergedMapping;
};

export const loadMergedRegionData = async (
    feature: Feature | null,
    data: FeatureDatum[] | null,
    layers: Layer[]
): Promise<MergedRegionData> => {
    if (feature == null || data == null) {
        return {};
    }
    let loadedLayers = layers.filter((l) => l.state == 'loaded');
    let [quantizer, _] = createColorQuantizerFromDatums(data);

    let regionToFeatureData = await createRegionToFeatureDataMapping(data);
    return createRegionToBoundaryMapping(loadedLayers).then(
        (regionToBoundaryData) => {
            let mergedData = mergeFeatureWithBoundaryDataAndQuantize(
                regionToFeatureData,
                regionToBoundaryData,
                quantizer
            );
            return mergedData;
        }
    );
};

export const layersToDeckGLLayers = (layers: Layer[]) => {
    return layers
        .filter((l) => l.state === 'loaded')
        .map((layer) => {
            if (layer.name == 'census_tracts') {
                return new GeoJsonLayer({
                    id: layer.name,
                    data: layer.data,
                    pickable: true,
                    stroked: true,
                    getLineColor: TEAL_COLOR_SCALE[0],
                    getFillColor: (d: any) => {
                        const color = d.properties.fillColor
                            ? d.properties.fillColor
                            : [0, 0, 0, 100];
                        return color;
                    },
                    // getElevation: (d: any) => d.properties.val,
                    // extruded: true,
                    filled: true,
                    getLineWidth: 10,
                    dataComparator: (oldData, newData) => {
                        return false;
                    },
                });
            } else if (layer.name === 'clusters') {
                return new GeoJsonLayer({
                    id: layer.name,
                    data: layer.data,
                    pickable: true,
                    autoHighlight: true,
                    stroked: true,
                    getLineColor: [0, 0, 0, 255],
                    getFillColor: layer.fillColor,
                    filled: true,
                    lineWidthUnits: 'pixels',
                    getLineWidth: 1,
                });
            } else {
                return new GeoJsonLayer({
                    id: layer.name,
                    data: layer.data,
                    pickable: true,
                    stroked: true,
                    getLineColor: [250, 250, 250, 150],
                    filled: false,
                    lineWidthUnits: 'pixels',
                    getLineWidth: 2,
                });
            }
        });
};
