import React from 'react';
import {
    AboutPage as OrigDCAboutPage,
    AboutPageSegment,
    Body,
    ContributeSection,
    DataClinicSection,
    Header,
    ProjectInfoSection as OrigProjectInfoSection,
} from '@dataclinic/dataclinic';

// TODO - move this fix to the dependency source
const DCAboutPage: React.FC<React.PropsWithChildren<any>> = OrigDCAboutPage;
const ProjectInfoSection: React.FC<React.PropsWithChildren<
    any
>> = OrigProjectInfoSection;

export const AboutPage: React.FC = () => {
    return (
        <DCAboutPage>
            <ProjectInfoSection
                appName={'NewerHoods'}
                appDescription="Spatial clustering and modeling for everyone else"
                appSubHeading="Find patterns in your spatial data"
            >
                <Body>
                    New York City’s (NYC’s) neighborhoods are a driving force in
                    the lives of New Yorkers—their identities are closely
                    intertwined and a source of pride. However, the history and
                    evolution of NYC’s neighborhoods don’t follow the rigid,
                    cold lines of statistical and administrative boundaries.
                    Instead, the neighborhoods we live and work in are the
                    result of a more organic confluence of factors.
                </Body>
                <Body>
                    Data Clinic developed NewerHoods with the goal of helping
                    individuals and organizations better advocate for their
                    communities by enabling them to tailor insights to meet
                    their specific needs. NewerHoods is an interactive web-app
                    that uses open data to generate localized features at the
                    census tract-level and machine learning to create
                    homogeneous clusters. Users are able to select
                    characteristics of interest (currently open data on housing,
                    crime, and 311 complaints), visualize NewerHood clusters on
                    an interactive map, find similar neighborhoods, and compare
                    them against existing administrative boundaries. The tool is
                    designed to enable users without in-depth data expertise to
                    compare and incorporate these redefined neighborhoods into
                    their work and life.
                </Body>
            </ProjectInfoSection>

            <AboutPageSegment>
                <Header>Data Sources</Header>
                <Body>
                    <ul>
                        <li>
                            <a
                                href="https://www1.nyc.gov/site/finance/taxes/property-annualized-sales-update.page"
                                target="_blank"
                            >
                                NYC Annualized Property Sales Data (2012-2017)
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www1.nyc.gov/site/planning/data-maps/open-data/dwn-pluto-mappluto.page"
                                target="_blank"
                            >
                                MapPLUTO (18v1)
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://developer.cityofnewyork.us/api/geoclient-api"
                                target="_blank"
                            >
                                Geoclient API v1.1
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www1.nyc.gov/site/finance/taxes/property-assessment-roll-archives.page"
                                target="_blank"
                            >
                                {' '}
                                Property Assessment Roll Archives
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://data.cityofnewyork.us/Public-Safety/NYPD-Complaint-Data-Historic/qgea-i56i"
                                target="_blank"
                            >
                                {' '}
                                NYPD Complaint Data Historic
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www1.nyc.gov/site/finance/taxes/property-assessment-roll-archives.page"
                                target="_blank"
                            >
                                311 Service Requests from 2010 to Present
                            </a>
                        </li>
                    </ul>
                </Body>
                <Header>References</Header>
                <Body>
                    <ul>
                        <li>
                            <a href="https://arxiv.org/abs/1707.03897">
                                ClustGeo: an R package for hierarchical
                                clustering with spatial constraints
                            </a>
                        </li>
                        <li>
                            <a href="http://chpcny.org/assets/MakingNeighborhoodsPaper.pdf">
                                Making Neighborhoods - Understanding New York
                                City Transitions 2000-2010
                            </a>
                        </li>
                    </ul>
                </Body>
            </AboutPageSegment>
            <DataClinicSection />
            <ContributeSection appName={'NewerHoods'} />
        </DCAboutPage>
    );
};
