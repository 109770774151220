import { useState, useEffect, useContext } from 'react';
import { Feature } from 'shared/types/Feature';
import { FeatureDatum } from 'shared/types/FeatureDatum';
import { DataActionType, DataContext } from 'contexts/DataContext';
import { loadFeature } from 'api';

export const useCombinedFeatures = (features: Feature[]) => {
    const [combinedData, setCombinedData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const { state, dispatch } = useContext(DataContext);
    const { featureData } = state;

    useEffect(() => {
        const loadedFeatures = Object.keys(featureData);
        if (features.every((f) => loadedFeatures.includes(f.id))) {
            setLoading(false);
            const combined = features.reduce(
                (combined: any, feature: Feature) => {
                    const data = featureData[feature.id];
                    data.forEach((datum: FeatureDatum) => {
                        const insert = { [feature.id]: datum.value };
                        const existing = combined[datum.boro_ct2010];
                        combined[datum.boro_ct2010] = existing
                            ? { ...existing, ...insert }
                            : insert;
                    });
                    return combined;
                },
                {}
            );
            setCombinedData(combined);
        } else {
            setLoading(true);
            const pending = features.filter(
                (f) => !loadedFeatures.includes(f.id)
            );
            pending.forEach((f) => {
                loadFeature(f).then((data) => {
                    dispatch({
                        type: DataActionType.ADD_FEATURE_DATA,
                        payload: {
                            id: f.id,
                            data,
                        },
                    });
                });
            });
        }
    }, [features, featureData]);
    return { combinedData, loading };
};
