import React from 'react';
import { Boundary } from 'shared/types/Boundary';
import Select from 'react-select';

interface BoundaryControlsProps {
    isShowingBoundary: boolean;
    setIsShowingBoundary: (isShowingBoundary: boolean) => void;
    activeBoundayLayerId: string | undefined;
    onSelectBoundary: (boundary: Boundary) => void;
    boundaries: Boundary[] | undefined;
    // bins: number[] | null;
}

type OptionType = {
    value: string;
    label: string;
};

export const BoundaryControls: React.FC<BoundaryControlsProps> = ({
    boundaries,
    activeBoundayLayerId,
    onSelectBoundary,
    isShowingBoundary,
    setIsShowingBoundary,
}) => {
    const boundaryOptions: OptionType[] = boundaries
        ? boundaries.map((b) => ({ value: b.id, label: b.name }))
        : [];

    const activeBoundaryOption = boundaryOptions.find(
        (bo) => bo.value === activeBoundayLayerId
    );

    return (
        // <Styles.BoundaryControl>
        <div style={{ width: '100%' }}>
            <Select
                isDisabled={!isShowingBoundary}
                onChange={(selectedBoundary) => {
                    const boundary: Boundary | undefined = boundaries?.find(
                        (b) => b.id === selectedBoundary?.value
                    );
                    if (boundary) {
                        onSelectBoundary(boundary);
                    }
                }}
                options={boundaryOptions}
                value={activeBoundaryOption}
            />
        </div>
    );
};
