import { useState, useEffect, useContext } from 'react';
import { DataContext } from 'contexts/DataContext';

export const useAvailableBoundaries = () => {
    const { state } = useContext(DataContext);
    const {
        boundaries,
        loadingFeatureManifest: loading,
        manifestError: error,
    } = state;
    return { boundaries, loading, error };
};
