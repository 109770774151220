import { useState, useEffect, useContext } from 'react';
import { Feature } from 'shared/types/Feature';
import { DataActionType, DataContext } from 'contexts/DataContext';
import { loadFeature } from 'api';

export const useFeature = (feature: Feature | null | undefined) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const { state, dispatch } = useContext(DataContext);

    const { featureData } = state;

    useEffect(() => {
        if (feature == null) {
            return;
        }

        if (!featureData[feature.id]) {
            setLoading(true);
            loadFeature(feature).then((data) => {
                dispatch({
                    type: DataActionType.ADD_FEATURE_DATA,
                    payload: { id: feature.id, data },
                });
                setLoading(false);
            });
        }
    }, [feature, featureData]);

    const data =
        feature && featureData[feature.id] ? featureData[feature.id] : null;
    return { loading, error, data };
};
