import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { DCThemeProvider as OrigDCThemeProvider } from '@dataclinic/dataclinic';
import { BrowserRouter as Router } from 'react-router-dom';

// TODO - move this fix to the dependency source
const DCThemeProvider: React.FC<React.PropsWithChildren<
    any
>> = OrigDCThemeProvider;

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <DCThemeProvider>
                <App />
            </DCThemeProvider>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
